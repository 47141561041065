<template lang="pug">
.button-component
  button(:style="style"
        :class="class"
        :disabled="disabled"
        @click="onClick")
    slot
</template>

<script>
export default {
  name: "ButtonComponent",
  props: {
    text: {
      type: String,
      default: "",
    },
    textColor: {
      type: String,
      default: "white",
    },
    backgroundColor: {
      type: String,
      default: "#29c853",
    },
    bounceOnHover: {
      type: Boolean,
      default: false,
    },
    changeColorOnHover: {
      type: Boolean,
      default: true,
    },
    hoverColor: {
      type: String,
      default: "#25b34a",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    animate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      spinner: false,
      check: false,
    };
  },
  computed: {
    style() {
      return {
        "--background-color": this.backgroundColor,
        "--text-color": this.textColor,
        "--hover-color": this.hoverColor,
        "--text": JSON.stringify(this.text),
      };
    },
    class() {
      return {
        onclick: this.spinner,
        check: this.check,
        disabled: this.disabled,
      };
    },
  },
  methods: {
    onClick() {
      this.$emit("clicked");
      if (this.animate) {
        this.spinner = true;
        setTimeout(() => {
          this.spinner = false;
          this.check = true;
          setTimeout(() => {
            this.check = false;
          }, 1500);
        }, 1500);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$green: #0f7dc2;
$gray: #bbbbbb;
$loader-size: 7em;
$check-height: $loader-size/2;
$check-width: $check-height/2;
$check-left: ($loader-size/6 + $loader-size/12);
$check-thickness: 3px;
$check-color: $green;

button {
  background-color: var(--background-color);
  color: var(--text-color);
  width: 120px;
  height: 45px;
  border-radius: 4px;
  transition: 0.2s;
  font-weight: 600;
  font-family: "Raleway", Helvetica, sans-serif;
  border: transparent;

  &:after {
    content: var(--text);
  }
  &:hover {
    background-color: var(--hover-color);
    transform: translateY(-2px);
    cursor: pointer;
  }
}
.disabled {
  background-color: lightgray;

  &:hover {
    background-color: lightgray;
    transform: translateY(0px);
  }
}

.onclick {
  &:after {
    display: inline-block;
    animation: 1.5s linear infinite rotating;
    border: solid 3px $green;
    border-bottom-color: #cfd0d1;
    border-radius: 50%;
    content: "";
    height: 15px;
    width: 15px;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.check {
  &:after {
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.3s;
    opacity: 1;
    content: "";
    display: inline-block;
    transform: rotate(45deg);
    height: 15px;
    width: 6px;
    margin: auto;
    border-bottom: 5px solid $green;
    border-right: 5px solid $green;
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
