import { createStore } from 'vuex'
import questionnaire from './modules/questionnaire/index'

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    questionnaire: questionnaire
  }
})
