<template lang="pug">
.question
  .title-container
    .title {{ title }}
  .question-wrapper
    .question-title {{ question }}
    .image-container
      img(:src='getImage')
    radio-input(:items='choices', @change-input='changeChoice')
</template>

<script>
import RadioInput from './RadioInput'

export default {
  name: 'Question',
  components: {
    RadioInput
  },
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    question: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: false
    },
    choices: {
      type: Array,
      required: true
    }
  },
  computed: {
    getImage() {
      return require(`@/assets/questions/${this.image}`)
    }
  },
  methods: {
    changeChoice(value) {
      this.$emit('change-input', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.question {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: 20px;
  width: 100%;

  .title-container {
    margin-top: 10px;

    .title {
      font-size: 2rem;
      font-weight: 600;
      color: #004f78;
      padding: 10px;
      margin-bottom: 15px;
    }
  }
  .question-wrapper {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .question-title {
      font-size: 1.125rem;
      padding-bottom: 20px;
    }
    .image-container {
      display: flex;
      justify-content: center;
      width: inherit;
      height: 325px;
      margin-bottom: 20px;

      img {
        width: inherit;
        animation: fadeInOpacity 2s;
      }
    }
  }

  .footer-container {
    display: inline-flex;
    justify-content: center;
    align-items: flex-end;
    font-weight: 500;
    text-decoration: underline;
    height: 80px;

    img {
      height: 30px;
    }
  }
  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>
