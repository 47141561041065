// namespace
export const QUESTIONNAIRE_NAMESPACE = 'questionnaire'

// actions
export const UPDATE_DATA = 'UPDATE_DATA'
export const GET_DATA = 'GET_DATA'

// mutations
export const SET_Q = 'SET_Q'
export const SET_SUBMIT_STATE = 'SET_SUBMIT_STATE'

// getters
export const GET_SESSION_ID = 'GET_SESSION_ID'
export const GET_ANSWERS = 'GET_ANSWERS'
export const GET_SUBMIT_STATE = 'SET_SUBMIT_STATE'
