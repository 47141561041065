
export const QUESTIONS = [
  {
    id: 1,
    title: "We'd love to get to know you better!",
    question: 'Choose all of the members that make up your family',
    image: 'Family.svg',
    choices: [
      { label: 'Myself, my partner and kid(s)' },
      { label: 'Myself and my partner' },
      { label: 'Myself and my kid(s)' },
      { label: 'Me, myself and I' }
    ]
  },
  // {
  //   id: 2,
  //   title: 'Your main method of transportation?',
  //   question: '',
  //   image: 'Walk.svg',
  //   choices: [
  //     { label: 'I drive my car' },
  //     { label: 'I ride the bus' },
  //     { label: 'I use my bike everywhere' },
  //     { label: 'I usually walk' }
  //   ]
  // },
  {
    id: 2,
    title: 'Do you ever use uber, or carshare services?',
    question: '',
    image: 'Taxi.svg',
    choices: [
      { label: 'That’s the only way I get around!' },
      { label: 'Sometimes they’re needed' },
      { label: 'Almost never, only if I have to' }
    ]
  },
  {
    id: 3,
    title: 'How frequently do you have food delivered to your home?',
    question: '',
    image: 'Burger.svg',
    choices: [
      { label: 'That’s how I get by every day!' },
      { label: 'Few times a week' },
      { label: 'Almost never' }
    ]
  },
  // {
  //   id: 4,
  //   title: 'How often do you eat out',
  //   question: '',
  //   image: 'HealthyMeal.svg',
  //   choices: [
  //     { label: 'Everyday!' },
  //     { label: 'It’s nice to sometimes take a break from cooking' },
  //     { label: 'Rarely, it’s unhealthy!' }
  //   ]
  // },
  {
    id: 4,
    title: 'Do you consider yourself a coffee person?',
    question: '',
    image: 'Coffee.svg',
    choices: [
      { label: 'Absolutely! I’m an addict!' },
      { label: 'Comes with the mood!' },
      { label: 'No, caffeine is harmful!' }
    ]
  },
  // {
  //   id: 5,
  //   title: 'How much do you spend on groceries per month?',
  //   question: '',
  //   image: 'Grocery.svg',
  //   choices: [
  //     { label: 'To the point that I’m almost broke' },
  //     { label: 'Eh, Decent, but not so much' },
  //     { label: 'Rarely spend on groceries!' }
  //   ]
  // },
  // {
  //   id: 7,
  //   title: 'How often do you make a purchase at a liquor store?',
  //   question: '',
  //   image: 'Drink.svg',
  //   choices: [
  //     { label: 'I stock-up my booze inventory all the time' },
  //     { label: 'Sometimes, if I don’t hit the bars' },
  //     { label: 'I’m not a drinker' }
  //   ]
  // },
  // {
  //   id: 8,
  //   title: 'Do you find home/tenant insurance a necessity?',
  //   question: '',
  //   image: 'Walk.svg',
  //   choices: [
  //     { label: 'Of course, I can’t risk it' },
  //     { label: 'Yes, if I can afford' },
  //     { label: 'Not really, unless I have to' }
  //   ]
  // },
  {
    id: 5,
    title: 'How often do you watch Netflix or Amazon Prime?',
    question: '',
    image: 'Netflix.svg',
    choices: [
      { label: 'I’m addicted' },
      { label: 'Only if I have time' },
      { label: 'Such a waste of time and money!' }
    ]
  },
  {
    id: 6,
    title: 'How significant is your monthly mobile, internet, and cable cost?',
    question: '',
    image: 'MobilePay.svg',
    choices: [
      { label: 'Aah! It’s pretty damn significant!' },
      { label: 'Eh, can’t complain!' },
      { label: 'I’m under the cheapest plans you can ever find!' }
    ]
  },
  // {
  // id: 8,
  //   title: 'How much do you spend on utilities per month?',
  //   question: '',
  //   image: 'Walk.svg',
  //   choices: [
  //     { label: 'Don’t even get me started!' },
  //     { label: 'Not much for an apartment' },
  //     { label: 'Utilities included in my rent' }
  //   ]
  // },
  {
    id: 7,
    title: 'Are you a gym-goer?',
    question: '',
    image: 'Walk.svg',
    choices: [
      { label: 'Yes, I’m a Gymoholic!' },
      { label: 'Eh, if I find time or if I can afford!' },
      { label: 'No, I’m a Gymophobic! ' }
    ]
  },
  {
    id: 8,
    title: 'Do you own any pets?',
    question: '',
    image: 'Pets.svg',
    choices: [
      { label: 'How can you live without one?!' },
      { label: 'One will join the crew soon!' },
      { label: 'Unfortunately, or fortunately not! ' }
    ]
  },
  // {
  //   id: 9,
  //   title: 'How much do you spend on any healthcare products not covered by insurance? ',
  //   question: '',
  //   image: 'Walk.svg',
  //   choices: [
  //     { label: 'I’m fed up by the expenses!' },
  //     { label: 'Some, but covered for the most part' },
  //     { label: 'I’m fully covered ' }
  //   ]
  // },
  {
    id: 9,
    title: 'Will you use our platform to buy discounted bundles that include such services?',
    question: '',
    image: 'Platform.svg',
    choices: [
      { label: 'Yes, absolutely!' },
      { label: 'Depends on the bundle' },
      { label: 'No, I am not interested' }
    ]
  },
  {
    id: 10,
    title: 'How much monthly savings would make you sign up for our platform?',
    question: '',
    image: 'Saving.svg',
    choices: [
      { label: 'minimum $50' },
      { label: 'minimum $150' },
      { label: 'Any amount would make me sign up!' }
    ]
  },
  {
    id: 11,
    title: 'Are you willing to bundle these services with your rent to save more?',
    question: '',
    image: 'Bundle.svg',
    choices: [
      { label: 'Yes!' },
      { label: 'No!' }
    ]
  },
  {
    id: 12,
    title: 'Will you be interested in using our cash-back or rewards credit card to save on these services?',
    question: '',
    image: 'Reward.svg',
    choices: [
      { label: 'Sure, why not!' },
      { label: 'No' },
      { label: 'Depends on the reward.' }
    ]
  }
]
