<template lang="pug">
.home
  first-section-component
  second-section-component
  third-section-component
</template>

<script>
import FirstSectionComponent from '../components/FirstSectionComponent'
import SecondSectionComponent from '../components/SecondSectionComponent'
import ThirdSectionComponent from '../components/ThirdSectionComponent'

export default {
  components: {
    FirstSectionComponent,
    SecondSectionComponent,
    ThirdSectionComponent
  }
}
</script>
