<template lang="pug">
.first-section-component
  .content
    h1 Nestivo is a customizable rental package system.
    h2 We package all your needs and services in one place. Become a Nester to improve your life quality. Nestivo is the best way to get rewarded for your daily needs. Whether it’s paying rent, buying groceries, or saving on childcare. There are so many ways to lower your bills, while improving your quality of life with ease.
    .selectionButtons
      selection-button(:type="'Renter'" @clicked="navigateToQuestionnaire")
      selection-button(:type="'Landlord'"
                       @clicked="navigateToUserDetails").landlord
  .buildingImg
    img(:src="require('../assets/mainpagebuilding.svg')")
</template>

<script>
import SelectionButton from './SelectionButton.vue'

export default {
  name: 'FirstSectionComponent',
  components: {
    SelectionButton
  },
  methods: {
    navigateToQuestionnaire() {
      this.$router.push('/questionnaire')
    },
    navigateToUserDetails() {
      this.$router.push('/userdetails')
    }
  }
}
</script>

<style lang="scss" scoped>
.first-section-component {
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: center;
  max-width: 1500px;
  padding-bottom: 160px;
  padding-top: 60px;
}
.content {
  margin: auto;
  padding-left: 20px;
  width: 700px;
}

.buildingImg {
  max-width: 50%;
  margin-left: 30px;
}

img {
  width: 100%;
}
.selectionButtons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding-top: 60px;

  .landlord {
    display: none;
  }
}

h1 {
  font-size: 3em;
  font-weight: 700;
  text-align: center;
  color: #303030;
  line-height: 125%;
  width: 100%;
  padding-bottom: 30px;
}

h2 {
  margin-top: 2em;
  margin-bottom: 1em;
  font-weight: 300;
  color: #303030;
  font-size: 110%;
}

h3 {
  margin-top: 3em;
}

@media only screen and (max-width: 1080px) {
  .first-section-component {
    flex-direction: column;
  }

  .content {
    max-width: 100%;
    margin: auto;
    text-align: center;
    padding: 0px;
  }

  .buildingImg {
    display: none;
  }

  .selectionButtons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  h1 {
    font-size: 2.5em;
    margin: auto;
  }

  h2 {
    max-width: 100%;
    padding-left: 10%;
    padding-right: 10%;
  }
}
</style>
