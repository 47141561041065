<template lang="pug">
.user-details
  .title-container
    .title(v-if="!getSubmitState") Finally, we just need some information
  .details-container(v-if="!getSubmitState")
    .name-input
      label(for='userName') Name
      input(type='text' id='userName' v-model='userDetails.name')
    .email-input
      label(for='userEmail') Email
      input(type='email' id='userEmail' v-model='userDetails.email')
    .city-input
      label(for='userCity') City
      input(type='text' id='userCity' v-model='userDetails.city')
    .city-input
      label(for='userState') State
      input(type='text' id='userState' v-model='userDetails.state')
  .confirmation-container(v-else)
    .title(v-if="!loading") Thanks for your submission, we will contact you with updates in the future!
    .loader(v-else)
  button-component(v-if="!getSubmitState"
                   text="Submit"
                   :animate="true"
                   @click="submitData"
                   :disabled="checkAllInputs")
</template>

<script>
import ButtonComponent from '../components/ButtonComponent'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { questionnaireTypes } from '../store/types'

export default {
  name: 'UserDetails',
  components: {
    ButtonComponent
  },
  data () {
    return {
      userDetails: {
        name: null,
        email: null,
        city: null,
        state: null
      },
      loading: false
    }
  },
  computed: {
    ...mapGetters(questionnaireTypes.QUESTIONNAIRE_NAMESPACE, {
      getSessionId: questionnaireTypes.GET_SESSION_ID,
      getAnswers: questionnaireTypes.GET_ANSWERS,
      getSubmitState: questionnaireTypes.GET_SUBMIT_STATE
    }),
    checkAllInputs () {
      let value = true
      Object.values(this.userDetails).forEach(val => {
        if (val !== null && val !== '') {
          value = false
        }
      })
      return value
    }
  },
  methods: {
    ...mapActions(questionnaireTypes.QUESTIONNAIRE_NAMESPACE, {
      updateData: questionnaireTypes.UPDATE_DATA
    }),
    ...mapMutations(questionnaireTypes.QUESTIONNAIRE_NAMESPACE, {
      setSubmitState: questionnaireTypes.SET_SUBMIT_STATE
    }),
    submitData () {
      const updatedUserDetails = { ...this.userDetails, ...this.getAnswers }
      const data = {
        sessionId: this.getSessionId,
        answers: updatedUserDetails
      }
      this.updateData(data)
      this.setSubmitState(true)
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 1500)
    }
  }
}
</script>

<style lang="scss" scoped>
.user-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: 50px;
  height: 520px;
  justify-content: center;

  .title-container {
    margin-top: 20px;

    .title {
      font-size: 2rem;
      font-weight: 600;
      color: #004f78;
      padding: 20px;

      @media only screen and (min-width: 410px) {
        margin-bottom: 30px;
      }
    }
  }
  .details-container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px;
    width: 70%;
    max-width: 400px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(136,144,195,0.2), 0 5px 15px 0 rgba(37,44,97,0.15);

    label {
      width: 60px;
      display: inline-block;
      font-size: 18px;
      font-weight: 500;
    }

    input {
      padding: 5px 5px;
      width: 70%;
      border-width: 2px;
      border-style: solid;
      border-color: #000;
      border-radius: 5px;
    }

    .email-input, .city-input, .state-input {
      margin-top: 10px;
    }
  }

  .confirmation-container {
    margin-top: 20px;
    width: 70%;
    .title {
      font-size: 2rem;
      font-weight: 600;
      color: #004f78;
      padding: 20px;
      margin-bottom: 30px;
      animation: fadeInOpacity 2s;
    }
    .loader {
      width: 68px;
      height: 68px;
      border: 8px solid #004f78;
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
      0% {
          transform: rotate(0deg);
      }
      100% {
          transform: rotate(360deg);
      }
    }
  }
  .button-component {
    padding-top: 50px;

    @media only screen and (min-width: 410px) {
      padding-top: 100px;
    }
  }
  .nav-buttons {
    display: flex;
    justify-content: center;
    width: 50%;
    margin-top: 5vh;
    margin-left: auto;
    margin-right: auto;
  }
  .footer-container {
    display: inline-flex;
    justify-content: center;
    align-items: flex-end;
    font-weight: 500;
    text-decoration: underline;
    height: 80px;

    img {
      height: 30px;
    }
  }
  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeOutOpacity {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
</style>
