import axios from 'axios'
import { toRaw } from 'vue'
import { UPDATE_DATA, GET_DATA } from './types'

export default {
  [UPDATE_DATA](_, { sessionId, answers }) {
    const value = toRaw(answers)

    axios
      .put(
        `https://nestivo-598e2-default-rtdb.firebaseio.com/${sessionId}.json`,
        {
          userDetails: value
        }
      )
      .catch((error) => {
        throw new Error(error)
      })
  },
  async [GET_DATA]() {
    try {
      const response = await axios.get(
        'https://nestivo-598e2-default-rtdb.firebaseio.com/.json'
      )
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  }
}
