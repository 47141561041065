<template lang="pug">
.input-box
  input(
    :placeholder='this.placeHolderText',
    @focus='handleFocus',
    @blur='handleBlur',
    @input='this.$emit("input-text", $event.target.value)'
  )
</template>

<script>
export default {
  name: 'InputBox',
  props: {
    placeHolder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      placeHolderText: this.placeHolder
    }
  },
  methods: {
    handleFocus() {
      this.placeHolderText = ''
    },
    handleBlur() {
      this.placeHolderText = this.placeHolder
    }
  }
}
</script>

<style lang="scss" scoped>
.input-box {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;

  input {
    background-color: rgba(246, 246, 246, 0.3);
    width: 100%;
    height: 45px;
    border: 2px solid #d8dde6;
    border-radius: 4px;
    font-size: 14px;
    color: white;
    transition: 0.3s;
    box-sizing: border-box;
    padding-left: 10px;

    &:hover {
      border: 2px solid #a3afc4;
    }

    &::placeholder {
      color: rgb(230, 226, 226);
      padding-left: 20px;
    }

    &:focus {
      padding-left: 25px;
      outline: none;
      border: none;
    }
  }
}
</style>
