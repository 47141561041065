import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../pages/Home'
import Questionnaire from '../pages/Questionnaire'
import Analytics from '../pages/Analytics'
import UserDetails from '../components/UserDetails'

const routes = [
  { path: '/', redirect: '/home' },
  { path: '/home', component: Home },
  { path: '/questionnaire', component: Questionnaire },
  { path: '/analytics-panel', component: Analytics },
  { path: '/userdetails', component: UserDetails }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
