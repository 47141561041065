<template lang="pug">
.header-component
  .logo-container
    nestivo-logo
  .button-container
    button-component.sign-up(
      v-if='!isOnQuestionnaire',
      :text='"Sign up"',
      @clicked='navigateToQuestionnaire'
    )
    //- button-component(:text="'Login'").login
</template>

<script>
import ButtonComponent from '../components/ButtonComponent'
import NestivoLogo from '../components/NestivoLogo.vue'

export default {
  name: 'HeaderComponent',
  components: {
    ButtonComponent,
    NestivoLogo
  },
  computed: {
    isOnQuestionnaire() {
      return this.$route.path === '/questionnaire'
    }
  },
  methods: {
    navigateToQuestionnaire() {
      this.$router.push('/questionnaire')
    }
  }
}
</script>

<style lang="scss" scoped>
.header-component {
  display: flex;
  flex-direction: row;
  padding-top: 25px;
  padding-bottom: 25px;
  width: 100%;
  max-width: 1200px;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
}
.sign-up {
  border: transparent;
}
.login {
  box-shadow: 0 2px 4px 0 rgba(136, 144, 195, 0.2),
    0 5px 15px 0 rgba(37, 44, 97, 0.15);
  border: transparent;
}

.logo-container {
  margin-left: 20px;
}

.button-container {
  margin-right: 20px;
  display: flex;
  height: 45px;
}

@media only screen and (max-width: 430px) {
  .button-container {
    display: flex;
    flex-direction: column;
  }
}
</style>
