<template lang="pug">
.selection-button(@click="emitClick")
  .logo(:class="logoImage")
  .title {{ getTitle }}
</template>

<script>
export default {
  name: 'SelectionButton',
  props: {
    type: {
      type: String,
      default: 'Renter'
    }
  },
  computed: {
    logoImage () {
      if (this.type === 'Renter') {
        return 'renter'
      } else if (this.type === 'Landlord') {
        return 'building'
      }
      return ''
    },
    getTitle () {
      if (this.type === 'Renter') {
        return 'Take the survey'
      } else if (this.type === 'Landlord') {
        return "I'm a landlord"
      }
      return ''
    }
  },
  methods: {
    emitClick () {
      this.$emit('clicked')
    }
  }
}
</script>
<style lang="scss" scoped>

.selection-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  border-radius: 6px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.295);;
  transition: .2s;
  background-color: white;

  &:hover {
    cursor: pointer;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.295);
    transform: scale(1.2);
  }
}

.title {
  font-family: 'Raleway', Helvetica, sans-serif;
  font-size: 20px;
  padding-top: 10px;
}

.renter {
  mask-image: url('../assets/renter.svg');
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 45px 45px;
  background-color: #1DE9B6;
  width: 45px;
  height: 45px;
  margin-right: 10px;
}
.building {
  mask-image: url('../assets/building.svg');
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 45px 45px;
  background-color: #BB86FC;
  width: 45px;
  height: 45px;
}

@media only screen and (max-width: 1080px) {
  .selection-button {
    max-width: 80%;
    margin: auto;
    margin-top: 15px;
  }
}

</style>
