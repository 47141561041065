import {
  SET_Q,
  SET_SUBMIT_STATE
} from './types'

export default {
  [SET_Q] (state, { question, value }) {
    state.questions[question] = value
  },
  [SET_SUBMIT_STATE] (state, value) {
    state.submitState = value
  }
}
