<template lang="pug">
.main
  header-component
  .body-container
    router-view
  .footer-container
    subscription-section
</template>
<script>
import HeaderComponent from './components/HeaderComponent'
import SubscriptionSection from './components/SubscriptionSection.vue'

export default {
  components: {
    HeaderComponent,
    SubscriptionSection
  }
}
</script>
<style>
body {
  margin: 0;
}
.main {
  font-family: 'Catamaran', Helvetica, serif;
  background-color: #f6f6f6;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #29c853;
  height: 335px;
}
</style>
