<template lang="pug">
.second-section-component
  .wrapper
    .streetImg
      img(:src="require('../assets/street.jpg')")
    .content
      .text
        h1 Rental Experience Redefined
        h2 Access and view all your rewards, savings, and deals that come with your home.
</template>

<script>
export default {
  name: 'SecondSectionComponent'
}
</script>

<style lang="scss" scoped>

.second-section-component {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  padding-top: 10vh;
  padding-bottom: 5vh;
  background-color: white;
}

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
}

.streetImg {
  width: 45%;
  margin-left: 20px;
}

img {
  width: 100%;
}

.content{
  width: 50%;
  margin-top: auto;
  margin-bottom: auto;
}

.text {
  margin-left: auto;
  margin-right: auto;
  width: 65%;
  min-width: 65%;
  padding-right: 5%;

}

h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.3;
  color: #303030;
}

h2 {
  padding-top: 3vh;
  font-size: 20px;
  font-family: 'Raleway', Helvetica, sans-serif;
  line-height: 1.6;
  font-weight: 400;
  color: rgb(74, 91, 86);
  width: 90%;
  text-align: left;
}

@media only screen and (max-width: 950px) {
  .second-section-component {
    padding-top: 5vh;
    padding-bottom: 10vh;
  }

  .streetImg {
    padding-top: 10vh;
    padding-left: 1vh;
  }

  .text {
    width:80%;
    padding-right: 0%;
  }
}

@media only screen and (max-width: 780px) {
  .second-section-component {
    flex-direction: column-reverse;
  }

  .content {
    min-width: 100%;
    padding-top: 10vh;
  }

  .streetImg {
    display: none;
  }

  img {
    padding-right: 0px;
  }

  .text {
    display: block;
    padding-left: 10px;
    padding-right: 10px;
  }

  h1 {
    font-size: 2.5em;
  }

  h1, h2 {
    text-align: center;
    width: 100%;
  }
}
</style>
