<template lang="pug">
.third-section-component
  .content
    .info-box
      a(class='info-item', href='#')
        img(:src="require('../assets/twemoji/svg/1f64c.svg')")
        h2 Save On Rent
        p Let your rent pay you
      a(class='info-item', href='#')
        img(:src="require('../assets/twemoji/svg/1f4b5.svg')")
        h2 Save On Services
        p Pay less for your needs
      a(class='info-item', href='#')
        img(:src="require('../assets/twemoji/svg/1f3a2.svg')")
        h2 Improve Your Life
        p Get rewarded with lifestyle perks
</template>

<script>

export default {
  name: 'ThirdSectionComponent'
}

</script>

<style lang="scss" scoped>

.third-section-component {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  padding: 3vh 1vh;
  background-color: #fff;
  min-height: 500px;
}

.content {
  width: 100%;
  margin: auto;
}

.info-box {
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 900px;
  padding: 10px 40px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(136,144,195,0.2), 0 5px 15px 0 rgba(37,44,97,0.15);
}
a.info-item {
  margin: auto;
  width: 220px;
  height: 250px;
  padding: 10px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
}

a:hover {
  background-color: #e4feec;
}

a.info-item * {
  margin: auto;
  text-align: center;
  color: #303030;
  padding-top: 10px;
}

a.info-item h2 {
  font-size: 2em;
  font-weight: 700;
  width: 90%;
  line-height: 1.2;
}

a.info-item p {
  width: 80%;
  color: #727272;
}

img {
  max-width: 60px;
}

@media only screen and (max-width: 500px) {

  .third-section-component {
    padding-bottom: 10vh;
    padding-left: 20px;
    padding-right: 20px;
  }

  a.info-item {
    height: 230px;
  }
}

</style>
