<template lang="pug">
.NestivoLogo(@click="navigateHome")
  img(:src="require('../assets/nestivo.png')")
</template>

<script>
export default {
  name: 'NestivoLogo',
  methods: {
    navigateHome () {
      return this.$router.push({ path: '/' })
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  width: 70px;
  height: 45px;
  transition: 0.2s;

   &:hover{
     opacity: 0.7;
     cursor: pointer;
   }
}
</style>
