<template lang="pug">
.progress-bar(:style="questionProgress")
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    totalNumberOfQuestions: {
      type: Number,
      required: true
    },
    currentQuestion: {
      type: Number,
      required: true,
      default: 0
    }
  },
  computed: {
    questionProgress () {
      const percentage = (this.currentQuestion / this.totalNumberOfQuestions) * 100
      return {
        width: `${percentage}%`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.progress-bar {
  display: flex;
  align-self: flex-start;
  height: 5px;
  background-color: teal;
}
</style>
