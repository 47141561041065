<template lang="pug">
.radio-button
  .container(v-for="(item, index) in items" :key="item")
    input(type="radio",
          ref="input",
          :id="index",
          :value="item.label",
          v-model="name",
          @change="emitChange(item.label)")
    label(:for="index",
          ref="label",
          tabindex=0) {{ item.label }}
</template>

<script>

export default {
  name: 'InputBox',
  props: {
    items: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      name: null
    }
  },
  methods: {
    emitChange (value) {
      this.$emit('change-input', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.radio-button {
  display: flex;
  flex-direction: column;
  align-self: center;

  .container {
    display: inline-flex;
    padding-bottom: 10px;
    margin: 5px;

    input {
      display: none;
    }
    input + label {
      cursor: pointer;
    }
    label {
      display: inline-flex;
    }
    label::before {
      content: '';
      width: 1.5rem;
      height: 1.5rem;
      border: 0.125rem solid rgb(136, 136, 136);
      border-radius: 50%;
      margin-right: 10px;
      transition: 0.20s;
    }

    input:checked + label::before {
      background: #29c853;
    }
  }
}
</style>
