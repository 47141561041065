<template lang="pug">
.questionnaire
  progress-bar(
    :totalNumberOfQuestions='getLastIndex',
    :currentQuestion='index'
  )
  question(
    v-if='!clickedFinish',
    :title='getTitle',
    :question='getQuestion',
    :image='getImage',
    :choices='getChoices',
    @change-input='updateAnswer'
  )
  user-details(v-else)
  .navbutton-container
    button-component(
      v-if='!isFirstIndex && !this.clickedFinish',
      :text='"Previous"',
      :backgroundColor='"#"',
      :textColor='"black"',
      :hoverColor='""',
      @click='goPreviousQuestion'
    )
    button-component(
      v-if='!this.clickedFinish',
      :text='showNavButtion.text',
      @click='showNavButtion.clickHandler',
      :disabled='toggleDisableButton'
    )
  span.security-message
    img.lockIcon(:src='require("../assets/lockIcon.png")')
    .info Your information is secure
</template>

<script>
import ButtonComponent from '../components/ButtonComponent'
import Question from '../components/Question'
import { QUESTIONS } from '../enums/questions'
import ProgressBar from '../components/ProgressBar'
import UserDetails from '../components/UserDetails'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import { questionnaireTypes } from '../store/types'

export default {
  components: {
    ButtonComponent,
    Question,
    ProgressBar,
    UserDetails
  },
  data() {
    return {
      questions: QUESTIONS,
      index: 0,
      clickedFinish: false,
      answer: {},
      toggleDisableButton: true
    }
  },
  computed: {
    ...mapGetters(questionnaireTypes.QUESTIONNAIRE_NAMESPACE, {
      getSessionId: questionnaireTypes.GET_SESSION_ID,
      getAnswers: questionnaireTypes.GET_ANSWERS
    }),
    showQuestion() {
      return this.questions[this.index]
    },
    isFirstIndex() {
      return this.index === 0
    },
    getLastIndex() {
      return this.questions.length - 1
    },
    getTitle() {
      return this.questions[this.index].title
    },
    getQuestion() {
      return this.questions[this.index].question
    },
    getImage() {
      return this.questions[this.index].image
    },
    getChoices() {
      return this.questions[this.index].choices
    },
    isLastIndex() {
      return this.index === this.getLastIndex
    },
    showNavButtion() {
      if (!this.isLastIndex && !this.clickedFinish) {
        return {
          text: 'Next',
          clickHandler: this.goNextQuestion
        }
      } else {
        return {
          text: 'Finish',
          clickHandler: this.goToUserDetails
        }
      }
    }
  },
  methods: {
    ...mapMutations(questionnaireTypes.QUESTIONNAIRE_NAMESPACE, {
      setValue: questionnaireTypes.SET_Q
    }),
    ...mapActions(questionnaireTypes.QUESTIONNAIRE_NAMESPACE, {
      updateData: questionnaireTypes.UPDATE_DATA
    }),
    goNextQuestion() {
      this.toggleDisableButton = true
      if (this.index === this.getLastIndex) {
        return
      }
      this.setValue(this.answer)
      this.index++
    },
    goToUserDetails() {
      this.setValue(this.answer)
      const userDetails = {
        sessionId: this.getSessionId,
        answers: this.getAnswers
      }
      this.updateData(userDetails)
      this.clickedFinish = !this.clickedFinish
    },
    goPreviousQuestion() {
      this.toggleDisableButton = false
      if (this.index === 0) {
        return
      }
      this.index--
    },
    updateAnswer(value) {
      this.toggleDisableButton = false
      const question = this.showQuestion.id
      this.answer = { question, value }
    }
  }
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@200;300;400;500;600;700&family=Raleway&display=swap');

.questionnaire {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: 20px;
  font-family: 'Catamaran', Helvetica, serif;

  .container {
    min-height: 500px;
  }

  .navbutton-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 20px;
  }
  .security-message {
    display: inline-flex;
    justify-content: center;
    align-items: flex-end;
    font-weight: 500;
    text-decoration: underline;
    height: 70px;

    img {
      height: 30px;
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
