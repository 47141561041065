import {
  GET_SESSION_ID,
  GET_ANSWERS,
  GET_SUBMIT_STATE
} from './types'

export default {
  [GET_SESSION_ID] (state) {
    return state.sessionId
  },
  [GET_ANSWERS] (state) {
    return state.questions
  },
  [GET_SUBMIT_STATE] (state) {
    return state.submitState
  }
}
