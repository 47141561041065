<template lang="pug">
.subscription-section
  .message Don't miss out on what's happening in Nestivo community
  .subscription-container
    input-box(:placeHolder="'your@email.com'"
              @input-text="updateEmailInput")
    .subscribe-button
      button-component(:text="'Subscribe'"
                      :backgroundColor="'white'"
                      :textColor="'black'"
                      :hoverColor="'white'"
                      :animate="true"
                      @clicked="submitEmail")
</template>

<script>
import ButtonComponent from '../components/ButtonComponent'
import InputBox from '../components/InputBox'
import { mapMutations } from 'vuex'
import { questionnaireTypes } from '../store/types'

export default {
  name: 'SubscriptionSection',
  components: {
    ButtonComponent,
    InputBox
  },
  data () {
    return {
      emailInput: ''
    }
  },
  methods: {
    ...mapMutations(questionnaireTypes.QUESTIONNAIRE_NAMESPACE, {
      setValue: questionnaireTypes.SET_Q
    }),
    submitEmail () {
      this.setValue({ question: 'email', value: this.emailInput })
    },
    updateEmailInput (event) {
      this.emailInput = event
    }
  }
}
</script>

<style lang="scss" scoped>
.subscription-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 180px;

  .message {
    font-family: 'Catamaran', Helvetica, serif;
    font-size: 2em;
    font-weight: 700;
    text-align: center;
    line-height: 1.3;
    color: white;
    max-width: 550px;
  }

  .subscription-container {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  @media only screen and (max-width: 600px) {
    .message {
      max-width: 350px;
      font-size: 1.5em;
      margin: auto;
    }
    .subscription-container {
      width: 100%;
      flex-direction: column;
      padding-top: 40px;
      align-items: center;
    }
    .subscribe-button {
      padding-top: 10px;
      padding-left: 0;
    }
  }
}
</style>
