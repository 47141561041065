<template lang="pug">
.analytics-panel
  .verification-container
  .analytics-container
    .total-number-inputs Total number of responses: {{ totalNumber }}
    ul.answers
      li(v-for='item in data') {{ item }}
</template>

<script>
import { mapActions } from 'vuex'
import { questionnaireTypes } from '../store/types'

export default {
  data() {
    return {
      data: null,
      totalNumber: null
    }
  },
  watch: {
    data() {
      this.totalNumber = Object.keys(this.data).length
    }
  },
  mounted() {
    this.showData()
  },
  methods: {
    ...mapActions(questionnaireTypes.QUESTIONNAIRE_NAMESPACE, {
      getData: questionnaireTypes.GET_DATA
    }),
    async showData() {
      this.data = await this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
.analytics-panel {
  height: 800px;

  .analytics-container {
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 1rem;
    margin: 2rem auto;
    width: 80%;
    height: 80%;
    overflow: scroll;
  }
  .total-number-inputs {
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 1rem;
    margin: 2rem auto;
  }
  li {
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 1rem;
    margin: 2rem auto;
  }
}
</style>
